import { useQuery } from "@apollo/client";
import { READ_ADMIN_IN_THE_NEWS } from "@roadflex/graphql";
import { InTheNewsType } from "@roadflex/types";

export const useReadAdminInTheNews = () => {
  const { data: inTheNewsList, loading: inTheNewsListLoading } = useQuery<{
    readAdminInTheNews: {
      inTheNews: InTheNewsType[];
    };
  }>(READ_ADMIN_IN_THE_NEWS, {
    fetchPolicy: "no-cache",
  });

  return {
    inTheNewsList,
    inTheNewsListLoading,
  };
};
