import { useQuery } from "@apollo/client";
import { READ_ADMIN_BLOG } from "@roadflex/graphql";
import { BlogType } from "@roadflex/types";

export const useReadAdminBlogs = () => {
  const { data: blogList, loading: blogListLoading } = useQuery<{
    readAdminBlogs: {
      blogs: BlogType[];
    };
  }>(READ_ADMIN_BLOG, {
    fetchPolicy: "no-cache",
  });

  return {
    blogList,
    blogListLoading,
  };
};
